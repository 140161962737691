export const ar = {
  direction: "rtl",
  welcome: "arabic",
  greetings: {
    morning: "Guten Morgen",
    afternoon: "Guten Tag",
    evening: "Guten Abend",
  },
  buttons: {
    change_to_german: "Wechseln zu Deutsch",
    change_to_english: "Wechseln zu Englisch",
  },
  messages: {
    thanks: "Vielen Dank, dass Sie unsere Anwendung nutzen!",
    info: "Diese Anwendung unterstützt mehrere Sprachen.",
  },
};
