export const fr = {
  direction: "ltr",
  welcome: "french",
  greetings: {
    morning: "Good morning",
    afternoon: "Good afternoon",
    evening: "Good evening",
  },
  buttons: {
    change_to_german: "Change to German",
    change_to_english: "Change to English",
  },
  messages: {
    thanks: "Thank you for using our application!",
    info: "This application supports multiple languages.",
  },
};
