import React from "react";
import { AuthImage, Logo } from "../../assets/images";
interface AuthLayoutComponentProps {
  title1?: string;
  title2?: string;
  title3?: string;
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutComponentProps> = ({
  title1,
  title2,
  title3,
  children,
}) => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center w-full lg:h-screen px-4 lg:px-28">
      {/* Left Section */}
      <div className="flex flex-col justify-start items-start p-8 w-full lg:w-1/2 border-gray-300">
        <div className="lg:h-96">
          <img
            alt={"logo"}
            src={Logo}
            className="w-28 h-28 object-cover mb-4"
          />
          <h1 className="text-2xl lg:text-3xl mb-2 font-montserrat_bold text-title">
            {title1}
          </h1>
          <h2 className="text-2xl lg:text-3xl mb-2 font-montserrat_bold text-title">
            {title2}
          </h2>
          <h3 className="text-2xl lg:text-3xl mb-2 font-montserrat_bold text-title">
            {title3}
          </h3>
        </div>
        <img
          alt={"Side"}
          src={AuthImage}
          className="w-full lg:w-96 h-auto lg:h-96 object-cover"
        />
      </div>

      {/* Divider */}
      <div className="hidden lg:block h-5/6 bg-text" style={{ width: 1 }} />

      {/* Right Section */}
      <div className="w-full lg:w-1/2 flex flex-col justify-start items-center px-4 lg:px-28 mt-8 lg:mt-0">
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
