export const offerList = [
  {
    img: "https://cdn.pixabay.com/photo/2022/01/25/04/42/bird-6965228_1280.jpg",
    title: "for individuell",
    type: "Basique",
    description: "A rejuvenating spa experience with world-class amenities.",
    price: 200,
    duration: "6 Mounth",
    benefits:
      "Relaxing massage * Access to sauna and steam room * Complimentary refreshments * Jusqu'à 250 000 de visites suivies",
  },
  {
    img: "https://static.vecteezy.com/system/resources/previews/027/254/720/non_2x/colorful-ink-splash-on-transparent-background-png.png",
    title: "for Startup",
    type: "Pro",
    description: "A rejuvenating spa experience with world-class amenities.",
    price: 50,
    duration: "Mounth",
    benefits:
      "Relaxing massage * Access to sauna and steam room * Complimentary refreshments * Jusqu'à 500 000 de visites suivies",
  },
  {
    img: "https://cdn.pixabay.com/photo/2022/01/25/04/42/bird-6965228_1280.jpg",
    title: "for individuell",
    type: "Basique",
    description: "A rejuvenating spa experience with world-class amenities.",
    price: 200,
    duration: "6 Mounth",
    benefits:
      "Relaxing massage * Access to sauna and steam room * Complimentary refreshments * Jusqu'à 250 000 de visites suivies",
  },
  {
    img: "https://static.vecteezy.com/system/resources/previews/027/254/720/non_2x/colorful-ink-splash-on-transparent-background-png.png",
    title: "for Startup",
    type: "Pro",
    description: "A rejuvenating spa experience with world-class amenities.",
    price: 50,
    duration: "Mounth",
    benefits:
      "Relaxing massage * Access to sauna and steam room * Complimentary refreshments * Jusqu'à 500 000 de visites suivies",
  },
  {
    img: "https://cdn.pixabay.com/photo/2022/01/25/04/42/bird-6965228_1280.jpg",
    title: "for individuell",
    type: "Basique",
    description: "A rejuvenating spa experience with world-class amenities.",
    price: 200,
    duration: "6 Mounth",
    benefits:
      "Relaxing massage * Access to sauna and steam room * Complimentary refreshments * Jusqu'à 250 000 de visites suivies",
  },
  {
    img: "https://static.vecteezy.com/system/resources/previews/027/254/720/non_2x/colorful-ink-splash-on-transparent-background-png.png",
    title: "for Startup",
    type: "Pro",
    description: "A rejuvenating spa experience with world-class amenities.",
    price: 50,
    duration: "Mounth",
    benefits:
      "Relaxing massage * Access to sauna and steam room * Complimentary refreshments * Jusqu'à 500 000 de visites suivies",
  },
  {
    img: "https://cdn.pixabay.com/photo/2022/01/25/04/42/bird-6965228_1280.jpg",
    title: "for individuell",
    type: "Basique",
    description: "A rejuvenating spa experience with world-class amenities.",
    price: 200,
    duration: "6 Mounth",
    benefits:
      "Relaxing massage * Access to sauna and steam room * Complimentary refreshments * Jusqu'à 250 000 de visites suivies",
  },
  {
    img: "https://static.vecteezy.com/system/resources/previews/027/254/720/non_2x/colorful-ink-splash-on-transparent-background-png.png",
    title: "for Startup",
    type: "Pro",
    description: "A rejuvenating spa experience with world-class amenities.",
    price: 50,
    duration: "Mounth",
    benefits:
      "Relaxing massage * Access to sauna and steam room * Complimentary refreshments * Jusqu'à 500 000 de visites suivies",
  },
];
export const colorOptions: { label: string; value: string }[] = [
  { label: "gray", value: "gray" },
  { label: "Pink", value: "pink" },
  { label: "Red", value: "red" },
  { label: "Blue", value: "blue" },
  { label: "green", value: "green" },
];
