import React from "react";
import AuthLayout from "../../../shared/auth-layout/auth-layout";
import CustomButton from "../../../shared/custom-button/custom-button";
import { ProfessorImage, StudentImage } from "../../../assets/images";
import { useNavigate } from "react-router-dom";

const Role = () => {
  const navigation = useNavigate();
  return (
    <AuthLayout
      title1={"Bienvenue à"}
      title2={"Fok Nje7ik"}
      title3={"Inscrivez-vous en tant que professeur ou élève"}
    >
      <div className="flex items-center mb-6">
        <span className="w-2 h-0.5 bg-placeholder"></span>
        <p className="text-placeholder text-xs mx-2">
          Choisissez Votre Profession
        </p>
        <span className="w-2 h-0.5 bg-placeholder"></span>
      </div>

      {/* Responsive grid for cards */}
      <div className="grid grid-cols-1 gap-6 ">
        <div
          onClick={() => navigation("/register")}
          className="flex hover:bg-text items-center w-full h-48 p-5 rounded-3xl border-2 border-placeholder cursor-pointer"
        >
          <img
            alt={"professor"}
            src={ProfessorImage}
            className={"w-32 h-32 object-cover"}
          />
          <p className="m-5 text-title text-xl lg:text-3xl font-montserrat_semi_bold">
            Espace Professeur
          </p>
        </div>

        <div
          onClick={() => navigation("/register-student")}
          className="flex hover:bg-text items-center w-full h-48 p-5 rounded-3xl border-2 border-placeholder cursor-pointer"
        >
          <img
            alt={"Student"}
            src={StudentImage}
            className={"w-32 h-32 object-cover"}
          />
          <p className="m-5 text-title text-xl lg:text-3xl font-montserrat_semi_bold">
            Espace Élève
          </p>
        </div>
      </div>

      <CustomButton
        text="Login"
        className={"w-48 h-14 mt-6 mb-4"}
        onClick={() => navigation("/login")}
      />
    </AuthLayout>
  );
};

export default Role;
