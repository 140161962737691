import React from "react";
import { Home } from "../../../assets/images";

const Welcome = () => {
  return (
    <div
      id="home"
      className="w-full  px-8 md:px-32 bg-backgroundHome flex flex-col lg:flex-row justify-between items-center pt-28 lg:pt-0 "
    >
      <div className="w-full md:w-11/12 flex flex-col justify-start mb-8 md:mb-0">
        <p className="text-primary bg-white py-3 px-4 w-min rounded-lg whitespace-nowrap font-montserrat_regular text-center">
          N'arrêtez jamais d'apprendre
        </p>
        <h1 className="text-title text-4xl md:text-6xl font-montserrat_bold my-3 md:my-5">
          Développez vos
        </h1>
        <h1 className="text-title text-4xl md:text-6xl font-montserrat_bold">
          compétences
        </h1>
        <h1 className="text-title text-4xl md:text-6xl font-montserrat_bold my-3 md:my-5">
          avec FOK NJE7IK
        </h1>
        <p className="text-text text-base md:text-lg font-montserrat_regular w-full md:w-9/12">
          FOK NJE7IK est une plateforme basée à travers la Tunisie spécialisée
          dans les formations accréditées et sur mesure. Nous brisons les
          barrières pour obtenir un diplôme.
        </p>
      </div>
      <img
        alt={"welcomeImage"}
        src={Home}
        className="w-full md:w-11/12 lg:w-1/2 h-3/6 md:h-2/6 object-contain"
      />
    </div>
  );
};

export default Welcome;
