import React from "react";
import { BlackUser } from "../../../assets/images";
import CustomButton from "../../../shared/custom-button/custom-button";
import { useNavigate } from "react-router-dom";

const Inscription = () => {
  const navigation = useNavigate();

  return (
    <div className="w-full h-[80vh] flex bg-backgroundHome items-center justify-center">
      <div
        style={{
          backgroundImage: `url(${BlackUser})`,
          backgroundSize: window.innerWidth > 1024 ? "contain" : "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="w-11/12 md:w-9/12 h-full bg-cover bg-center flex justify-center flex-col px-6 md:px-12"
      >
        <p className="rounded-lg py-3 px-5 mb-5 w-min bg-text text-primary bg-opacity-15 font-montserrat_medium">
          Baccalaureat
        </p>
        <h1 className="font-montserrat_medium text-title text-2xl md:text-4xl w-full md:w-7/12 mb-5">
          Ne perdez pas de temps cet Été. Développer tes compétences.
        </h1>
        <p className="text-text w-full md:w-1/2 font-montserrat_regular mb-6">
          Profitez de votre temps libre pour apprendre de nouvelles compétences
          ou approfondir vos connaissances.
        </p>
        <CustomButton
          text={"S'inscrire maintenant"}
          width={"w-min"}
          onClick={() => navigation("/register-student")}
          className={"text-nowrap text-xs"}
        />
      </div>
    </div>
  );
};

export default Inscription;
