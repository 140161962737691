import React , {useContext, useState} from "react";
import { subscribe } from "../../../assets/images";
import {SnackbarContext } from "../../../config/hooks/use-toast";
const Newsletter = () => {
  const snackbarContext = useContext(SnackbarContext);

  const [email , setEmail] = useState<string>("");

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = () => {
    if(email === ""){
      snackbarContext?.showMessage("Abonnez-vous","Veuillez entrer votre email.", "error");
      return;
    }
    const regext = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(!regext.test(email)){
      snackbarContext?.showMessage("Abonnez-vous","Veuillez entrer un email valide.", "error");
      return;
    }
    snackbarContext?.showMessage("Abonnez-vous","Vous êtes abonné avec succès.", "success");
    setEmail("");
  }
  return (
    <div
      id="contact"
      className="my-20 w-full h-[50vh] flex bg-backgroundHome items-center justify-center"
    >
      <div
        style={{
          backgroundImage: `url(${subscribe})`,
          backgroundSize: window.innerWidth > 1024 ? "contain" : "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="w-full h-full bg-cover lg:bg-contain bg-center flex justify-center flex-col items-center"
      >
        <h1 className="font-montserrat_medium text-white text-2xl md:text-4xl w-11/12 md:w-5/12 text-center mb-5">
          Abonnez-vous pour obtenir une mise à jour chaque nouveau cours
        </h1>
        <p className="text-white w-10/12 md:w-1/3 font-montserrat_regular text-xs md:text-base text-center mb-6">
          Profitez de votre temps libre pour apprendre de nouvelles compétences.
          Plus de 900 étudiants apprennent quotidiennement avec FOK NJE7IK.
          Abonnez-vous pour découvrir nos nouveaux cours.
        </p>
        <div className="flex items-center flex-col md:flex-row w-full justify-center">
          <input
            type="email"
            placeholder="Entrer votre email"
            className="border-0 bg-white bg-opacity-10 h-12 md:h-14 w-[80vw] md:w-[30vw] rounded-s-lg ps-4 text-white"
            value={email}
            onChange={handleEmailChange}
          />
          <button 
            className="px-4 py-2 h-12 mt-2 md:mt-0 text-nowrap md:h-14  font-montserrat_regular text-white rounded-e-lg rounded-s-lg md:rounded-s-none bg-[#e978a7] hover:bg-[#f2609d] transition-all duration-100"
            onClick={() => handleSubscribe () }
            >
            Abonnez-vous
          </button>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
